const authors = [
  {
    name: 'Armando Medina',
    imageUrl: 'john.png',
    bio:
      'Debemos saber, sabremos',
    facebook: 'https://www.facebook.com/profile.php?id=100027643854225',
    twitter: 'https://www.twitter.com/M2718281DINA',
    instagram: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  },
]

module.exports = authors